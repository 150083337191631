import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from 'app/main/shared/enums/base.enum';
import {Template} from "../templates/template.model";

export class Titulacao extends BaseModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public tipo?: string,
        public tipoRequerimento?: string,
        public formulario?: string,
        public validadePadrao?: number,
        public template?: Template,
        public templatePublicacao?: Template
    ) {
        super();
    }

    static tipos: BaseEnum[] = [
        {valor: 'AUTORIZACAO', descricao: 'Autorização'},
        {valor: 'CADASTRO', descricao: 'Cadastro'},
        {valor: 'LICENCA', descricao: 'Licença'},
        {valor: 'PORTARIA', descricao: 'Portaria da Outorga'},
        {valor: 'OUTROS_ATOS', descricao: 'Outros Atos'},
        {valor: 'TERMOS', descricao: 'Termos'}
    ];

    static tiposRequerimento: BaseEnum[] = [
        {valor: 'LICENCIAMENTO', descricao: 'LICENCIAMENTO'},
        {valor: 'OUTORGA', descricao: 'OUTORGA'},
        {valor: 'TODOS', descricao: 'TODOS'}
    ];

    static fromJson(json: any): Titulacao {
        if (json === undefined || json === null) {
            return null;
        }
        return new Titulacao(
            json.id,
            json.descricao,
            json.tipo,
            json.tipoRequerimento,
            json.formulario,
            json.validadePadrao,
            Template.fromJson(json.template),
            Template.fromJson(json.templatePublicacao)
        );
    }

    static fromJsons(json: any): Titulacao[] {
        const titulacoes: Titulacao[] = [];
        if (json === undefined || json === null) {
            return [];
        } else {
            json.forEach(titulacao => {
                titulacoes.push(this.fromJson(titulacao));
            });
            return titulacoes;
        }
    }
}
