import {Injectable, Injector} from '@angular/core';
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {BaseService} from "./base.service";
import {PessoaFisicaCorporativo} from "../models/pessoa-fisica-corporativo.model";
import {environment} from "../../../../environments/environment";
import {PessoaJuridicaCorporativo} from "../models/pessoa-juridica-corporativo.model";

@Injectable({
    providedIn: 'root'
})
export class ScorpApiService extends BaseService<PessoaFisicaCorporativo> {
    private urlResourceScrop = environment.URL_SCORP_API;

    constructor(protected injector: Injector) {
        super('scorp', injector, PessoaFisicaCorporativo.prototype, PessoaFisicaCorporativo.fromJson);
    }

    getByPessoaFisica(cpf: string): Observable<PessoaFisicaCorporativo> {
        const url = `${this.urlResourceScrop}/api/pessoas-fisicas/cpf/${cpf}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    getByPessoaJuridica(cnpj: string): Observable<PessoaJuridicaCorporativo> {
        const url = `${this.urlResourceScrop}/api/pessoas-juridicas/cnpj/${cnpj}`;
        return this.http
        .get(url)
        .pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }


    getDestinatarios(descRazaoSocial: string): Observable<any> {
        const url = `${this.urlResourceScrop}/api/pessoas-fisicas/destinatario/${descRazaoSocial}`;
        return this.http.get(url).pipe(
            map(response => response),
            catchError(this.handleError.bind(this))
        );
    }

    getSiglaCompletaUsuarioLogado(): Observable<any> {
        const url = `${this.urlResourceScrop}/api/organizacoes-administrativas/sigla-completa`;
        return this.http.get(url);
    }

    getOrganizacoesAdministrativasPorGrupoCamunda(grupoCamunda: string): Observable<any> {
        const url = `${this.urlResourceScrop}/api/organizacoes-administrativas/listar-organizacao-por-grupo-camunda/${grupoCamunda}`;
        return this.http.get(url);
    }

    protected handleError(error: any): Observable<any> {
        return throwError(error);
    }
}